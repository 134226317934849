<template>
    <b-overlay :show='showLoader'>
        <StoreCategories @category-changed="loadAllData" class="mb-5" :categories="categories"/>
        <filters :slider="sliderValues" :categories="categories" @on-slider-change="onPriceSliderChange" @on-select="onSelect" @remove-filters="removeFilters" @category-changed="loadAllData"/>
        <h1 v-if="category" class="category-title">{{category.title}}</h1>
        <blue-background class="mt-1"/>
        <div class="mt-1 bg-white">
            <b-row class="items">
                <template v-if="webShopItems && webShopItems.length > 0">
                    <b-col v-for="item of webShopItems" :key="item.id" sm="12" md="6" lg="4" class="mb-2 d-flex col-item">
                        <sale-card :object="item"/>
                    </b-col>
                </template>
                <template v-else>
                    <b-col class="text-center">
                        <h3>V tej kategoriji ni oglasov</h3>
                    </b-col>
                </template>
            </b-row>
        </div>
        <pagination v-if="pagination" v-model="pagination" class="mt-1"/>
    </b-overlay>
</template>

<script>
    import {BOverlay, BRow, BCol} from 'bootstrap-vue'
    import SaleCard from '@/views/Components/SaleCard'
    import BlueBackground from '@/views/Components/BlueBackground'
    import Pagination from '@/views/Components/Pagination'
    import Filters from '@/views/Components/Filters'
    import StoreCategories from '@/views/Components/StoreCategories'
    export default {
        components: {
            Filters,
            Pagination,
            BlueBackground,
            SaleCard,
            BOverlay,
            BRow,
            BCol,
            StoreCategories
        },
        props: {
            listing_type: {
                type: String
            }
        },
        data() {
            return {
                queryBrands: null,
                selectedBrands: [],
                webShopItems: [],
                categories: [],
                //mainCategory: null,
                //show: false,
                //top_categories: [],
                category: null,
                isMainCategory: false,
                showLoader: false,
                perPage: 12,
                currentPage: 1,
                selectedBrand: '',
                brands: [],
                slider_items: [{title: 'Cenovni razpon'}],
                sliderValues: {
                    value: [0, 100],
                    min: 0,
                    max: 1000000000,
                    interval: 1
                },
                filterOptions: [
                    { value: 'id.sort=-1', text: 'Najnovejše' },
                    { value: 'price.sort=1', text: 'Najnižja cena' },
                    { value: 'price.sort=-1', text: 'Najvišja cena' }
                ],
                selectedFilterOption: 'id.sort=-1',
                pagination: null,
                selectedListingTypeIDs: [],
                created: false,
                checkType: [],
                backForwardNavigation: false
            }
        },
        methods: {
            async loadAllData() {
                this.showLoader = true
                this.created = false
                try {
                    await this.loadCategories()
                    await this.getConfiguration()
                    await this.loadManufacturers()
                    await this.loadWebShops()
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                    this.created = true
                }
            },
            async loadItems() {
                this.showLoader = true
                try {
                    await this.loadManufacturers()
                    await this.setBreadchrumb()
                    await this.loadWebShops()
                } catch (error) {
                    this.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    this.showLoader = false
                }
            },
            getSelectedFilterOptionQueryParam() {

                if (!this.created) {
                    let sort = null
                    for (const property in this.$route.query) {
                        if (property.includes('.sort')) sort = property
                    }

                    if (sort !== null) {
                        this.selectedFilterOption = `${sort}=${this.$route.query[sort]}`
                        return this.selectedFilterOption
                    }
                }


                let sortQueryParam = this.filterOptions[0].value
                const split = this.selectedFilterOption.split('=')
                if (split.length !== 2) return sortQueryParam

                // if there is filter option in query, then this.$route.query[split[0]] won't return undefined,
                // but will return either 1 or -1 (asc or desc), then we check if it matches 1 or -1
                if (this.$route.query[split[0]] && Number(this.$route.query[split[0]]) === Number(split[1])) {
                    sortQueryParam = this.selectedFilterOption
                }

                if (this.selectedFilterOption !== sortQueryParam) this.selectedFilterOption = sortQueryParam


                return `&${sortQueryParam}`
            },
            getQueryParams() {

                const page = this.$route.query.page ?? 1
                const perPage = this.$route.query.perPage ?? 12
                this.perPage = perPage
                const priceFrom = this.$route.query.price_from ?? ''
                const priceTo = this.$route.query.price_to ?? ''
                const searchQuery = this.$route.query.search ?? ''
                // const manufacturerTitle = this.$route.query.manufacturer_title ?? ''
                // this.selectedBrand = manufacturerTitle

                if (!this.created && priceFrom !== '' && priceTo !== '') this.sliderValues.value = [priceFrom, priceTo]

                const pageQueryParam = (page ? `&page=${page}&` : '')
                const perPageQueryParam = (perPage ? `&perPage=${perPage}&` : '')
                const priceFromQueryParam = (priceFrom ? `&price.gte=${priceFrom}&` : '')
                const priceToQueryParam = (priceTo ? `&price.lte=${priceTo}&` : '')
                const serachQueryParam = (searchQuery ? `&search=${searchQuery}&` : '')
                // const manufacturerTitleQueryParam = (manufacturerTitle ? `&manufacturerTitle}&` : '')
                const sortQueryParam = this.getSelectedFilterOptionQueryParam()

                const p = [
                    pageQueryParam,
                    perPageQueryParam,
                    priceFromQueryParam,
                    priceToQueryParam,
                    serachQueryParam,
                    sortQueryParam
                    // manufacturerTitle
                ]

                return p.join('')
            },
            async loadWebShops() {
                const queryParams = this.getQueryParams()
                let query_brands = ''
                if (this.selectedBrands && this.selectedBrands.length > 0) {
                    query_brands = ''

                    for (const brand of this.selectedBrands) {
                        query_brands += `&manufacturer_title.[]=${brand}&`
                    }
                }

                let categories = ''
                if (this.$route.query.category_id) {
                    categories = `&split_categories.[]=${this.$route.query.category_id}&`
                } else if (this.isMainCategory) {
                    for (const item of this.category.children) {
                        categories += `&split_categories.[]=${item.bbis_id}&`
                    }
                } else categories = `&split_categories.[]=${this.category.bbis_id}&`

                const res = await this.$http.get(`/api/user/v1/web_shop/?${categories}&&${queryParams}${query_brands}`)
                this.webShopItems = res.data.items ?? []
                this.pagination = res.data.pagination

                const minPrice = Number(res.data.min_price ?? 0)
                const maxPrice = Number(res.data.max_price ?? 0)
                if (!this.created) {
                    // when a page is first loaded, it is checked to see if they already exist price_from and price_to in URL
                    // if exist already call ES again without conditions and get min and max price of all c2c items
                    // we need this to filter the price in the slider
                    if (this.$route.query.price_from && this.$route.query.price_to) {
                        const data = await this.$http.get(`/api/user/v1/web_shop/?${categories}`)
                        const min = Number(data.data.min_price ?? 0) * 1
                        const max = Number(data.data.max_price ?? 0) * 1

                        const minPriceFromUrl = Number(this.$route.query.price_from)
                        const maxPriceFromUrl = Number(this.$route.query.price_to)

                        this.sliderValues.value = [minPriceFromUrl, maxPriceFromUrl]
                        this.sliderValues.min = min
                        this.sliderValues.max = max
                    } else {
                        this.sliderValues.value = [minPrice, maxPrice]
                        this.sliderValues.min = minPrice
                        this.sliderValues.max = maxPrice
                    }
                }
            },
            async loadCategories() {
                const res = await this.$http.get('/api/user/v1/web_shop/category')
                this.categories = res.data

                this.setBreadchrumb()
            },
            async loadManufacturers() {
                let query_string = ''
                if (this.$route.query.price_from && this.$route.query.price_to) {
                    query_string = `?price.gte=${this.$route.query.price_from}&price.lte=${this.$route.query.price_to}`
                }

                let categories = ''
                if (this.isMainCategory) {
                    for (const item of this.category.children) {
                        if (item.parent_category_id === this.$route.params.category_id) categories = item.bbis_id
                        break
                    }
                } else categories = this.category.bbis_id

                const res = await this.$http.get(`/api/user/v1/web_shop/manufacturer/${categories}${query_string}`)
                this.brands = res.data
            },
            async onPriceSliderChange(sliderValues) {
                this.backForwardNavigation = false

                let valuePrevFrom = 0
                let valuePrevTo = 0

                if (this.$route.query) {
                    valuePrevFrom = parseInt(this.$route.query.price_from)
                    valuePrevTo = parseInt(this.$route.query.price_to)
                }

                if (valuePrevFrom !== sliderValues.value_from || valuePrevTo !== sliderValues.value_to) {
                    const query = Object.assign({}, this.$route.query)
                    query.price_from = sliderValues.value_from
                    query.price_to = sliderValues.value_to
                    await this.$router.replace({ query })
                } else {
                    return
                }
                await this.loadItems()
                this.backForwardNavigation = true
            },
            async onSelect(name, val) {
                if (name === 'category') {
                    if (this.$route.query.category_id === val.bbis_id) return
                    await this.$router.replace({path: this.$route.fullPath, query:  {category_id: val.bbis_id}})
                    await this.loadItems()
                    await this.setBreadchrumb()
                }
            },
            async removeFilters() {
                this.$router.push({name: 'web-shop-items-list'})
                /*this.created = false
                if (this.$route.fullPath !== `/izdelki/${this.$route.params.category_id}`) await this.$router.replace({})
                await this.loadItems()
                this.created = true*/
            },
            setBreadchrumb() {
                let category = null
                let categoryBbisId = null
                if (this.$route.query.category_id) categoryBbisId = this.$route.query.category_id
                else {
                    category = this.categories.find(x => x.id === this.$route.params.category_id)
                    if (category !== undefined) {
                        this.$route.meta.breadcrumb = [{text: category.title, to: `/izdelki/${category.id}`, active: true}]
                    }
                }
                this.isMainCategory = true
                if (category === undefined || categoryBbisId !== null) {
                    if (categoryBbisId !== null) {
                        const mainCategory = this.categories.find(x => x.children.find(y => y.bbis_id === categoryBbisId))
                        category = mainCategory.children.find(x => x.bbis_id === categoryBbisId)
                        if (category !== undefined) {
                            this.$route.meta.breadcrumb = [
                                {text: mainCategory.title, to: `/izdelki/${mainCategory.id}`, active: false},
                                {text: category.title, to: `/izdelki/${category.id}`, active: true}
                            ]
                        }
                    } else {
                        const mainCategory = this.categories.find(x => x.children.find(y => y.id === this.$route.params.category_id))
                        if (mainCategory && mainCategory !== undefined) {
                            category = mainCategory.children.find(x => x.id === this.$route.params.category_id)
                            if (category !== undefined) {
                                this.$route.meta.breadcrumb = [
                                    {text: mainCategory.title, to: `/izdelki/${mainCategory.id}`, active: false},
                                    {text: category.title, to: `/izdelki/${category.id}`, active: true}
                                ]
                            }
                        } else {
                            const mainCategory = this.categories.find(x => x.children.find(y => y.children.find(z => z.id === this.$route.params.category_id)))
                            if (mainCategory) {
                                const subCategory1 = mainCategory.children.find(x => x.children.find(y => y.id === this.$route.params.category_id))
                                if (subCategory1) {
                                    category = subCategory1.children.find(x => x.id === this.$route.params.category_id)
                                    if (category !== undefined) {
                                        this.$route.meta.breadcrumb = [
                                            {text: mainCategory.title, to: `/izdelki/${mainCategory.id}`, active: false},
                                            {text: subCategory1.title, to: `/izdelki/${subCategory1.id}`, active: false},
                                            {text: category.title, to: `/izdelki/${category.id}`, active: true}
                                        ]
                                    }
                                }
                            }
                        }
                    }
                    this.isMainCategory = false
                }
                this.category = category
                this.$root.$emit('force-update-breadcrumbs')
            },
            getMatchingCategory(category_id) {
                for (const mainCategory of this.categories) {

                    if (mainCategory.id === category_id) {
                        return mainCategory
                    }

                    for (const childCategory of mainCategory.children) {
                        if (childCategory.id === category_id) {
                            return childCategory
                        }
                    }
                }
            },
            async getConfiguration() {
                try {
                    const res = await this.$http.get('/api/user/v1/webshop/configuration/homepage')
                    this.configuration = res.data

                    this.current_category = this.getMatchingCategory(this.configuration.current_category)

                    this.top_categories = []

                    for (const category of this.configuration.top_categories) {
                        this.top_categories.push(this.getMatchingCategory(category))
                    }

                } catch (error) {
                    this.$printError('Prišlo je do napake pri nalaganju podatkov')
                }
            }
        },
        async mounted() {
            this.queryBrands = this.$route.query['manufacturer_title.[]']
            this.$scrollToTop()

            if (!this.queryBrands) {
                this.queryBrands = []
            } else if (!Array.isArray(this.queryBrands)) {
                this.queryBrands = [this.queryBrands]
            }
            this.selectedBrands = this.queryBrands

            await this.loadAllData()
        },
        watch: {
            async '$route.query'(query_params) {

                if (!this.backForwardNavigation) {
                    return
                }

                this.$scrollToTop()

                let min = 0
                let max = 0

                // We set slider and input to right values
                if (query_params && Object.keys(query_params).length === 0) {
                    min = this.sliderValues.min
                    max = this.sliderValues.max
                } else {
                    min = query_params.price_from
                    max = query_params.price_to
                }

                this.$refs.sliderRefWebShop.sliderMinInput = min
                this.$refs.sliderRefWebShop.sliderMaxInput = max
                this.sliderValues.value = [min, max]

                await this.loadWebShops()
            },
            async 'selectedFilterOption'(val, oldVal) {
                if (!this.created) return

                const value = val.split('=')
                const valueOld = oldVal.split('=')

                // deleting all filter options from URL
                const query = Object.assign({}, this.$route.query)
                for (const filterOption of this.filterOptions) {
                    const split = filterOption.value.split('=')
                    delete query[split[0]]
                }

                // if it's first option (sort by date_posted ascending) selected (default value)
                // then don't show it in URL
                if (val !== oldVal)  {

                    if (val === 'id.sort=-1') {
                        delete query[valueOld[0]]
                    } else {
                        query[value[0]] = value[1]
                    }

                    await this.$router.replace({query})
                }

                // after sort change, set current page to 1 (if it's not 1)
                // if pagination is null, then just load ads
                if (this.pagination && this.pagination.current_page !== 1) this.pagination.current_page = 1
                else await this.loadWebShops()
            },
            'pagination.current_page'() {
                if (!this.created || !this.pagination) return

                /* const currentPage = (this.pagination ? this.pagination.current_page : 1)
                this.$router.push({'path': this.$route.fullPath, 'query': {'page': currentPage}})
*/
                this.$router.push({'path': this.$route.fullPath, 'query': {'page': this.pagination.current_page, 'perPage': this.pagination.per_page = 12}})

                this.loadWebShops()
            },
            '$route.query.search'(val) {

                if (val) {
                    this.loadWebShops()
                }
            },
            async '$route.params.category_id'(val) {
                if (val) {
                    this.showLoader = true
                    await this.setBreadchrumb()
                    await this.loadWebShops()
                    this.showLoader = false
                }
            }
        }
    }
</script>

<style scoped>
.category-title {
  font-weight: 700;
  font-size: 33px;
  line-height: 40px;
  display: flex;
  flex-direction: row;
}
.category-title:before, .category-title:after{
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #000000;
  margin: auto;
}
.category-title:before {
  margin-right: 17px
}
.category-title:after {
  margin-left: 17px
}

@media (min-width: 768px) and (max-width: 992px) {
  .items > .col-item:nth-child(odd)::after {
    border-right: 0.031rem solid #009ED3;;
    content:"";
    position:absolute;
    top: 5rem;
    right:0;
    height: 250px;
  }
}
@media only screen and (min-width: 992px) {
  .items > .col-item:nth-child(3n - 1)::after {
    border-right: 0.031rem solid #009ED3;;
    content:"";
    position:absolute;
    top: 5rem;
    right:0;
    height: 250px;
  }
  .items > .col-item:nth-child(3n - 2)::after {
    border-right: 0.031rem solid #009ED3;;
    content:"";
    position:absolute;
    top: 5rem;
    right:0;
    height: 250px;
  }
}
</style>
